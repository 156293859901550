import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Blog from '../views/Blog.vue'
const routes = [
    {
        path:'/',
        name:'Home',
        component: Home
    },
    {
        path: '/blog',
        name: 'Blog',
        component: Blog
        // component: () => import('../views/Blog.vue')
    }
]
const router = createRouter(
    {
        history: createWebHashHistory(),
        routes
    }
)

export default router