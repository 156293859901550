<template>
  <div class="common-layout">
    <el-container>
      <el-header>
        <el-row>
          <el-col :span="1">
            <div class="grid-content ep-bg-purple"/>
            <router-link to="/">
              <el-button type="primary" plain>首页</el-button>
            </router-link>
          </el-col>
          <el-col :span="1">
            <div class="grid-content ep-bg-purple-light"/>
            <router-link to="/blog">
              <el-button type="success" plain>博客</el-button>
            </router-link>
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
      <el-footer>
          <el-link href="https://beian.miit.gov.cn/" target="_blank">京ICP备2023036713号</el-link>
      </el-footer>
    </el-container>

  </div>
</template>

<script>

</script>

<style>
</style>
