<template>
  <div>
    <el-carousel :interval="3000" arrow="always">
      <el-carousel-item v-for="item in carouseData" :key="item">
        <div class="demo-image__lazy">
          <img :src="item" alt=""/>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>

<h1>首页</h1>
  <br/>
  <el-button>Default</el-button>
  <el-button type="primary">Primary</el-button>
  <el-button type="success">Success</el-button>
  <el-button type="info">Info</el-button>
  <el-button type="warning">Warning</el-button>
  <el-button type="danger">Danger</el-button>
</template>

<script>
 export default {
   setup(){
     // const carouseData = [
     //   {url: require("@/assets/img/img01.jpeg")},
     //   {url: require("@/assets/img/img02.jpeg")},
     //   {url: require("@/assets/img/img03.jpeg")},
     //   {url: require("@/assets/img/img04.jpeg")},
     // ]
     const carouseData = [
       'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
       'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
       'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
       'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
       'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
       'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
       'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg',
     ]
     return {carouseData}
   }
 }
</script>

<style scoped>
</style>